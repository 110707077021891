import React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import Header from "../components/Header";
import Spacer from "../components/Spacer";
import Footer from "../components/Footer";

interface PrivacyPageProps {
  location: {
    pathname: string;
  };
}

const Privacy = ({ location }: PrivacyPageProps) => {
  return (
    <Layout location={location}>
      <Header />
      <Wrapper>
        <Heading>
          <HR />
          <Spacer size={20} />
          <H1> Privacy Policy </H1>
          <small>
            Last changed
            <time>
              {" "}
              31<sup>st</sup> May 2022
            </time>
          </small>
          <Spacer size={20} />
          <Text>
            This privacy policy explains how personal information is collected,
            used, stored, and with whom we share that information by AFRICA
            FINTECH LIMITED. This policy also contains information about when we
            share your personal information with third parties (such as our
            service providers and credit bureaus).
          </Text>
          <Spacer size={25} />
          <h2> Application of this Privacy Policy </h2>
          <Spacer size={15} />
          <Text>
            The application of this privacy policy applies generally to the
            business of AFRICA FINTECH LIMITED and serves to set out our
            approach to personal data, inclusive of personal data of our
            consumer users ("Users"). This Privacy Policy applies to use of our
            websites, applications, other online services and/or any related
            services, sales, marketing, promotional or events, and social media
            activities (collectively, our "Services"). For our Users, this
            Privacy Policy is part of our Terms of Use. This Privacy Policy
            applies to your use of (regardless of means of access) our Services.
            You may access or use our Services through a desktop, laptop, mobile
            phone, tablet, or other consumer electronic device (each, a
            "Device"). This Privacy Policy also applies to offline interactions
            with AFRICA FINTECH LIMITED.
          </Text>
          <Spacer size={20} />
          <Text>
            By accessing or using our Services, you agree to this Privacy
            Policy.
          </Text>
        </Heading>
        <article>
          <Section>
            <H3> Privacy and Data Protection </H3>
            <Text>
              Protecting your privacy is very important to us. Please review our
              Privacy Policy in order to better understand our commitment to
              maintaining your privacy and protecting your data, as well as our
              use and disclosure of your information. If you receive information
              about another AFRICA FINTECH LIMITED customer through the
              Services, you must keep the information confidential and only use
              it in connection with the service. You may not disclose or
              distribute a AFRICA FINTECH LIMITED user’s information to a third
              party or use the information for marketing purposes without that
              user’s express consent.
            </Text>
          </Section>
          <Section>
            <H3>What information do we collect?</H3>
            <InnerTextWraper>
              <h4>Information you give us on AFRICA FINTECH LIMITED</h4>
              <Text>
                We collect and use any information you supply when you interact
                with any of our services touch points. As a user, we will also
                ask you to create a profile and account login information for
                your AFRICA FINTECH LIMITED account, and you share details like
                your name, BVN, ID, address and a picture. When you provide your
                mobile phone number, we may ask for your consent to receive text
                messages relating to our Services on that number.  Additionally,
                we may request explicit permission to see other information like
                your address book, location, photos and data from your camera.
              </Text>
              <ul>
                <LI>
                  Details you give when you sign up for a AFRICA FINTECH LIMITED
                  profile and account, like your BVN, name, date of birth,
                  gender, phone number, residential address, and email address.
                </LI>
                <LI>Your profile picture.</LI>
                <LI>
                  Our services may require us to collect a video of your face
                  doing a liveness check to confirm that you are the one
                  upgrading your AFRICA FINTECH LIMITED account tier
                </LI>
                <LI>
                  We then share this video with a third-party service to
                  facilitate our liveness check.
                </LI>
                <LI>
                  The choice to upload a video of your face during liveness
                  check may be opted out for, however, this will limit what you
                  can do with your AFRICA FINTECH LIMITED account.
                </LI>
                <LI>
                  We will not share the video of your face that we collect
                  during a liveness check with advertisers or anyone else other
                  than the third-party service that facilitates the liveness
                  check process.
                </LI>
                <LI>
                  If at any time you choose to withdraw your consent for us to
                  use this video for your liveness check, we will delete it.
                </LI>
                <LI>
                  Also, if you choose to delete your AFRICA FINTECH LIMITED
                  account, we will delete any data we have previously collected
                  from you including the video of your face uploaded during the
                  liveness check.
                </LI>
                <LI>
                  Information you give us through the in-app chat so we can help
                  you.
                </LI>
              </ul>
            </InnerTextWraper>
            <InnerTextWraper>
              <h4>
                Information we collect when you use AFRICA FINTECH LIMITED
              </h4>
              <ul>
                <LI>The mobile network and operating system you use</LI>
                <LI>Your IP address and device ID</LI>
                <LI>
                  Your phone contacts so you can make airtime purchases or pay
                  contacts on AFRICA FINTECH LIMITED
                </LI>
              </ul>
            </InnerTextWraper>
            <InnerTextWraper>
              <h4>Information we get from third parties</h4>
              <Text>
                In our KYC (Know Your Customer) process, we run checks on the ID
                information you supply during signup. We may collect your
                Personal Information from a User where your Personal Information
                is filled in a Next-of-Kin form.
              </Text>
            </InnerTextWraper>
            <InnerTextWraper>
              <h4>Information you give us when you contact us</h4>
              <Text>
                If you contact via other means than the in-app chat, we collect
                the following information so we can answer your questions or
                take action.
              </Text>
              <ul>
                <LI>
                  The phone number you’re calling from and the information you
                  give us during the call
                </LI>
                <LI>
                  The email address you use and the contents of your email (and
                  any attachments).
                </LI>
                <LI>
                  Public details from your social media profile (like Facebook,
                  Instagram or Twitter) if you reach out to us via these
                  platforms and the contents of your messages or posts to us.
                </LI>
              </ul>
            </InnerTextWraper>
            <InnerTextWraper>
              <h4> Information Automatically Collected </h4>
              <Text>
                We (or our service providers acting on our behalf) may collect
                information about your use of our Services. This information may
                include Personal Information as well as statistical information
                that does not identify you ("Analytics"). Some Analytics may be
                correlated with Personal Information. When Analytics are,
                directly or indirectly, associated or combined with Personal
                Information, such Analytics will be considered Personal
                Information for purposes of this Privacy Policy.
              </Text>
            </InnerTextWraper>
          </Section>
          <Section>
            <H3> How do we use your information </H3>
            <Text>
              In line with the Data Protection Regulation, we are mandated to
              have a lawful basis for using your personal information. At least
              one of the following must apply: contractual or legal duty,
              legitimate interest, public interest, vital individual interest or
              consent.
            </Text>
            <InnerTextWraper>
              <h4> Contractual Obligation </h4>
              <Text>
                We collect certain data from you to fulfil the contract we have
                with you or to enter into a contract with you. We use this data
                to:
              </Text>
              <ul>
                <LI>
                  Give you the services we agreed to in line with our terms and
                  conditions.
                </LI>
                <LI>
                  Send you messages about your account and other services you
                  use if you get in touch, or if we need to tell you about
                  something.
                </LI>
                <LI>
                  Exercise our rights under contracts we’ve entered into with
                  you, like managing, and receiving your money
                </LI>
                <LI>Investigate and resolve complaints and other issues.</LI>
              </ul>
            </InnerTextWraper>
            <InnerTextWraper>
              <h4> Legal Duty </h4>
              <Text>
                We have to ensure we aren’t breaking any laws by banking you by
                preventing illegal activities like money laundering and fraud.
                To do this, we need your data to:
              </Text>
              <ul>
                <LI>Confirm your identity when you sign up or get in touch.</LI>
                <LI>
                  Prevent illegal activities like money laundering, tax evasion
                  and fraud.
                </LI>
                <LI>
                  Keep records of information we hold about you in line with
                  legal requirements.
                </LI>
                <LI>
                  Adhere to banking laws and regulations (these mean we
                  sometimes need to share customer details with regulators, tax
                  authorities, law enforcement or other third parties).
                </LI>
              </ul>
            </InnerTextWraper>
            <InnerTextWraper>
              <h4> Legitimate Interest </h4>
              <Text>
                In some instances, we need to use the data you supply us for our
                legitimate interests. This means we’re using your data in a way
                that you might expect us to, for a reason which is in your
                interest and doesn't override your privacy, interests or
                fundamental rights and freedoms.
              </Text>
            </InnerTextWraper>
            <InnerTextWraper>
              <h4> Consent </h4>
              <Text>
                For certain information, we’ll ask for your consent. We’ll ask
                for your consent to:
              </Text>
              <ul>
                <LI>
                  Market and communicate our products and services. You can
                  always unsubscribe from receiving these if you want to:
                </LI>
                <LI>
                  Help protect you against fraud by tracking the location of
                  your phone if you’ve
                </LI>
                <LI>
                  Help protect you against fraud by tracking the location of
                  your phone if you’ve authorized it
                </LI>
                <LI>View your contact list for airtime purchases.</LI>
              </ul>
            </InnerTextWraper>
          </Section>
          <Section>
            <H3> When do we delete your data?</H3>
            <Text>
              We will hold your personal information on AFRICA FINTECH LIMITED’s
              systems for as long as is necessary to fulfill the purpose for
              which it was collected or to comply with legal, regulatory or
              internal policy requirements.
            </Text>
          </Section>
          <Section>
            <H3> What are your rights? </H3>
            <Text>
              As a user, you have the right to withdraw any consent you’ve
              previously given us regarding our services. You may also ask us to
              delete our record of you, restrict the way in which we use your
              personal information, request a copy of the information we hold
              about you, ask that we update the personal information we hold
              about you or correct such personal information which you think is
              incorrect or incomplete, and we will grant this request as long as
              we’re legally allowed to. You also have the right to object to us
              using your information for our marketing purposes or any
              additional services we may be offering you. Lastly, you may also
              withdraw any consent you’ve previously given us.
            </Text>
          </Section>
          <Section>
            <H3> How to make a complaint </H3>
            <Text>
              We’re extremely committed to respecting and protecting your
              information. If you have questions regarding your data privacy
              rights or would like to submit a related data privacy rights
              request, please contact us by:
            </Text>
            <ul>
              <LI>Communicating with us via our app</LI>
              <LI>
                Sending an email to us at{" "}
                <a href="mailto:dpo@africafintech.co.uk">
                  dpo@africafintech.co.uk
                </a>
              </LI>
            </ul>
          </Section>
          <Section>
            <H3> Changes to this document </H3>
            <Text>
              Subject to applicable law, we may revise this Privacy Policy at
              any time and in our sole discretion. When we revise this Privacy
              Policy, we will post the revised version via our Services and will
              update the date at the top of this Privacy Policy. The revised
              Privacy Policy will be effective upon posting via our Services,
              unless otherwise set forth therein or as otherwise required by
              applicable law. You are free to decide whether or not to accept a
              revised version of this Privacy Policy, but accepting this Privacy
              Policy, as revised, is required for you to continue accessing or
              using our Services. If you do not agree to the terms of this
              Privacy Policy or any revised version of this Privacy Policy, your
              sole recourse is to terminate your access and use of our Services.
              Except as otherwise expressly stated by us, your access and use of
              our Services are subject to the version of this Privacy Policy in
              effect at the time of access or use.
            </Text>
          </Section>
        </article>
      </Wrapper>
      <Footer />
    </Layout>
  );
};

const Wrapper = styled.main`
  background-color: var(--black-100);
  color: var(--white);

  padding-block-start: 100px;
  /* padding-block-end: 100px; */
  padding-inline: 30px;
`;

const Heading = styled.header`
  padding-block: 40px;
`;

const HR = styled.hr`
  display: block;
  width: 80px;
  height: 4px;
  background-color: var(--green);
  border: none;
`;

const H1 = styled.h1`
  font-size: clamp(2.5rem, 6vw - 1rem, rem);
`;

const Text = styled.p`
  line-height: 1.5;
  font-size: 14px;
  letter-spacing: 0.1px;
`;

const Section = styled.section`
  background-color: hsl(180, 1.6%, 11.96%);
  padding: 30px;

  + section {
    margin-top: 20px;
  }
  a {
    text-decoration: underline;
    text-decoration-color: var(--orange);
    color: inherit;
    text-decoration-thickness: 2px;
  }
`;

const H3 = styled.h3`
  font-size: 1.2rem;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 80px;
    height: 4px;
    background-color: var(--green);
  }
`;

const LI = styled.li`
  padding-left: 10px;
  line-height: 1.5;
  font-size: 14px;
  letter-spacing: 0.1px;
  ::marker {
    content: "-";
    font-size: 1.5em;
  }
`;

const InnerTextWraper = styled.div`
  padding-block: 10px;

  h4 {
    margin-bottom: 5px;
  }
`;

export default Privacy;
